<script setup lang="ts">
import { formProps } from '../formProps'

const attrs = useAttrs()
const model = defineModel<string>()
const props = defineProps({
  rounded: {
    type: Boolean,
    default: false
  },
  validation: {
    type: Object,
    default: null
  },
  labelAlign: {
    type: String,
    default: 'left'
  },
  icon: {
    type: String,
    default: null
  },
  ...formProps
})

const inputId = attrs.name?.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')

const inputInvalid = ref(false)
const inputValid = ref(false)
const error = ref(null)
const validate = () => {
  if (props.validation) {
    inputInvalid.value = !props.validation.safeParse(model.value).success
    error.value = props.validation.safeParse(model.value).error?.issues[0]?.message
    if (!error.value) inputValid.value = true
  }
}

const updateModel = (event: Event) => {
  const target = event.target as HTMLInputElement
  model.value = target.value
  if (inputInvalid.value) {
    validate()
  }
}

defineExpose({ validate })
</script>

<template>
  <ClientOnly>
    <div class="root" :class="{ invalid: inputInvalid, valid: inputValid }">
      <label :for="inputId" :class="[hideLabel ? 'sr-only' : '', `text-${props.labelAlign}`]">
        {{ props.label }}
      </label>
      <div class="input-wrapper">
        <i v-if="icon" class="ri-checkbox-circle-line prepend-icon"/>
        <input
          v-bind="$attrs"
          :id="inputId"
          :value="model"
          :placeholder="props.placeholder"
          :type="props.type"
          :disabled="props.disabled || props.readonly"
          :class="{ rounded: props.rounded, 'has-icon': icon }"
          @input="updateModel"
          @blur="validate"
        >
        <i v-if="inputValid && !inputInvalid" class="ri-checkbox-circle-line valid"/>
      </div>
      <small v-if="props.helpText" class="help-text">{{helpText}}</small>
      <small v-if="inputInvalid" class="error-message">{{ error }}</small>
    </div>
  </ClientOnly>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/input.pcss";

.root {
  width: fit-container;
  display: grid;
  text-align: left;
}

input {
  @mixin mp-input;
  font-size: 1rem;
}

input.has-icon {
  padding-left: 2.25rem;
}

input.rounded {
  @mixin mp-input;
  font-size: 1rem;
  border-radius: 2rem;
}

input:disabled {
  opacity: 0.6
}

label {
  @mixin mp-input-label;
  cursor: not-allowed;

  &.text-center {
    text-align: center;
  }

  &.text-right {
    text-align: right;
  }
}

.help-text {
  @mixin mp-helper-text;
  display: block;
}

.error-message {
  @mixin mp-error-text;
  display: block;
}

.invalid input {
  @mixin mp-input-invalid;
}

.input-wrapper {
  position: relative;
}

i.valid {
  position: absolute;
  right: 0.65rem;
  top: 0.9rem;
  z-index: 10;
  color: var(--color-green-400);
}

i.prepend-icon {
  position: absolute;
  left: 0.65rem;
  top: 0.9rem;
  z-index: 10;
  color: var(--color-slate-400);
}
</style>
